import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import MenuItem from "@mui/material/MenuItem";

// import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
// import AccountCircle from "@mui/icons-material/AccountCircle";
// import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

import { logout as adminLogout } from "../../slices/authSlice";

import { mobileRoutes } from "../../utils/constants";

const MobileNavLinks = ({
	// handleProfileClick,
	isAuthenticated,
	isDesktopCountryOpen,
	setDesktopCountryAnchorEl,
	logo,
	toggleFlag,
}) => {
	const dispatch = useDispatch();

	return (
		<div style={{ textAlign: "right" }}>
			{isAuthenticated && (
				<>
					{mobileRoutes?.map((route) => (
						<MenuItem
							component={Link}
							to={route.link}
							key={route.link}
							{...(route.name === "Help Center" ? { target: "_blank" } : {})}
						>
							<Typography
								variant="body2"
								component="div"
								sx={{ textTransform: "capitalize" }}
							>
								{route.name}
							</Typography>
						</MenuItem>
					))}
					<MenuItem onClick={() => dispatch(adminLogout())}>
						<Typography
							variant="body2"
							component="div"
							sx={{ textTransform: "capitalize" }}
						>
							logout
						</Typography>
					</MenuItem>
				</>
			)}
			<Box
				sx={{
					display: "flex",
					justifyContent: "center",
					mt: "1rem",
				}}
			>
				<Box
					sx={{ height: "2.3rem", width: "2.3rem" }}
					id="country-selector-mobile"
					aria-controls={
						isDesktopCountryOpen ? "desktop-country-menu" : undefined
					}
					aria-haspopup="true"
					aria-expanded={isDesktopCountryOpen ? "true" : undefined}
					onClick={(event) => {
						if (toggleFlag) {
							setDesktopCountryAnchorEl(event.currentTarget);
						}
					}}
				>
					<img
						src={logo}
						alt="country flag"
						height="100%"
						width="100%"
						style={{ objectFit: "cover", borderRadius: "50%" }}
					/>
				</Box>
			</Box>
		</div>
	);
};

export default MobileNavLinks;
