import * as React from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";

import {
	Dialog,
	DialogContent,
	useTheme,
	Slide,
	useMediaQuery,
	Box,
	Typography,
} from "./muiComponents";
import PasswordComponent from "./PasswordComponent";
import SubmitButton from "./SubmitButton";
import { changePassword } from "../../api/common/auth";
import {
	specialCharacterCheck,
	digitCheck,
	uppercaseCheck,
} from "../../utils/constants";
import { hashString } from "../../utils/helpers/functions";
import PasswordCheckCompoennt from "./PasswordCheckCompoennt";

const fields = {
	currentPassword: "",
	newPassword: "",
	confirmPassword: "",
};

const validationSchema = yup.object({
	currentPassword: yup
		.string("Please enter your password")
		.min(7, "Password should be of minimum 7 characters length")
		.matches(
			specialCharacterCheck,
			"Password should contain at least one special character"
		)
		.matches(digitCheck, "Password should contain at least one digit")
		.matches(
			uppercaseCheck,
			"Password should contain at least one uppercase character"
		)
		.required("Password is required"),
	newPassword: yup
		.string("Please enter your password")
		.min(7, "Password should be of minimum 7 characters length")
		.matches(
			specialCharacterCheck,
			"Password should contain at least one special character"
		)
		.matches(digitCheck, "Password should contain at least one digit")
		.matches(
			uppercaseCheck,
			"Password should contain at least one uppercase character"
		)
		.required("Password is required"),
	confirmPassword: yup
		.string("Please enter your password")
		.min(7, "Password should be of minimum 7 characters length")
		.matches(
			specialCharacterCheck,
			"Password should contain at least one special character"
		)
		.matches(digitCheck, "Password should contain at least one digit")
		.matches(
			uppercaseCheck,
			"Password should contain at least one uppercase character"
		)
		.required("Password is required")
		.oneOf([yup.ref("newPassword"), null], "Passwords must match"),
});

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

export default function AlertDialogSlide({ open, handleClose }) {
	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
	const { user, region } = useSelector((state) => state.auth);

	const handleSubmit = async (values, stopSubmitLoading, resetForm) => {
		if (values.newPassword !== values.confirmPassword) {
			toast.error("New password and confirm password should be same");
			return;
		}
		try {
			const payload = {
				username: user.username,
				newPassword: hashString(values.newPassword),
				oldPassword: hashString(values.currentPassword),
				region: region?.region,
			};

			await changePassword(payload);
			toast.success("Password changed successfully");
			stopSubmitLoading();
			resetForm();
			localStorage.clear();
			handleClose();
		} catch (err) {
			stopSubmitLoading();
			if (err.response) {
				toast.error(err.response.data.message);
			} else if (err.request) {
				toast.error("Internal Server Error");
			} else {
				toast.error("Error", err.message);
			}
		}
	};

	const formik = useFormik({
		initialValues: fields,
		validationSchema,
		onSubmit: (values) => {
			handleSubmit(
				values,
				() => formik.setSubmitting(false),
				() => formik.resetForm()
			);
		},
	});

	return (
		<Dialog
			open={open}
			TransitionComponent={Transition}
			keepMounted
			onClose={(_, reason) => {
				if (reason !== "backdropClick") {
					handleClose();
				}
			}}
			aria-describedby="alert-dialog-slide-description"
			fullScreen={fullScreen}
			sx={{
				borderRadius: "50px",
				"& .MuiDialog-paper": {
					borderRadius: "20px",
					maxWidth: { md: "80vw !important", xs: "100vw" },
					overflow: "hidden", // Hide scrollbars
				},
				"&::-webkit-scrollbar": {
					width: "0", // Hide the width of the scrollbar
				},
				"&::-webkit-scrollbar-thumb": {
					background: "transparent", // Hide the scrollbar thumb
				},
			}}
		>
			<DialogContent
			// sx={{
			// 	width: { md: "80vw", xs: "100vw" },
			// 	height: { md: "80vh", xs: "100vh" },
			// 	backgroundColor: "white",
			// 	overflow: "hidden",
			// 	"&::-webkit-scrollbar": {
			// 		width: "0",
			// 	},
			// 	"&::-webkit-scrollbar-thumb": {
			// 		background: "transparent",
			// 	},
			// }}
			>
				<Typography
					variant="h6"
					sx={{
						textAlign: "center",
						fontWeight: 700,
						textTransform: "capitalize",
						color: "#303030",
						p: { md: "2rem", xs: "1rem" },
					}}
				>
					change password
				</Typography>
				<Box
					component="form"
					sx={{
						p: { md: "1rem 3rem", xs: "1rem" },
						// border: "1px solid red",
					}}
					noValidate
					autoComplete="off"
					onSubmit={formik.handleSubmit}
				>
					<Box sx={{ mb: "1rem" }}>
						<PasswordComponent
							value={formik.values.currentPassword}
							onChange={formik.handleChange}
							required
							label="Current Password"
							name="currentPassword"
							id="currentPassword"
							error={
								formik.touched.currentPassword &&
								Boolean(formik.errors.currentPassword)
							}
							helperText={
								formik.touched.currentPassword && formik.errors.currentPassword
							}
						/>
					</Box>
					<Box sx={{ mb: "1rem" }}>
						<PasswordComponent
							value={formik.values.newPassword}
							onChange={formik.handleChange}
							required
							label="New Password"
							name="newPassword"
							id="newPassword"
							onCopy={(e) => e.preventDefault()}
							error={
								formik.touched.newPassword && Boolean(formik.errors.newPassword)
							}
							helperText={
								formik.touched.newPassword && formik.errors.newPassword
							}
						/>
					</Box>
					<Box sx={{ mb: "1rem" }}>
						<PasswordComponent
							value={formik.values.confirmPassword}
							onChange={formik.handleChange}
							required
							label="Confirm Password"
							name="confirmPassword"
							id="confirmPassword"
							error={
								formik.touched.confirmPassword &&
								Boolean(formik.errors.confirmPassword)
							}
							helperText={
								formik.touched.confirmPassword && formik.errors.confirmPassword
							}
						/>
					</Box>

					<PasswordCheckCompoennt password={formik.values.newPassword} />

					<SubmitButton
						text="update password"
						loading={formik.isSubmitting}
						disabled={!formik.dirty || formik.isSubmitting}
					/>
				</Box>
			</DialogContent>
		</Dialog>
	);
}
