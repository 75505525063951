import { Bars } from "react-loader-spinner";

const SpinLoader = () => {
	return (
		<div
			style={{
				display: "flex",
				alignItems: "center",
				justifyContent: "center",
				width: "100%",
				height: "100%",
			}}
		>
			<Bars type="Bars" color="#e41f26" height={50} width={50} visible />
		</div>
	);
};

export default SpinLoader;
