import { Navigate, Outlet, useNavigate } from "react-router-dom";

const ProtectedRoute = ({
	isAllowed,
	redirectPath = "/partner-admin/login",
	children,
}) => {
	const navigate = useNavigate();

	if (!isAllowed) {
		// Pass the current route to the login component
		return (
			<Navigate to={redirectPath} state={{ from: navigate.location }} replace />
		);
	}

	return children || <Outlet />;
};

export default ProtectedRoute;
