import React, { lazy, Suspense } from "react";
import { Route, Routes, useLocation, Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { AnimatePresence } from "framer-motion";

import Layout from "../../components/Layout/Layout";
import Loader from "../../components/common/FullSpinLoader";
import ProtectedRoutes from "../../components/common/ProtectedRoutes";
import Logout from "../../components/common/Logout";
import Login from "../views/Login";

const NotFound = lazy(() => import("../views/NotFoundPage/NotFoundPage"));
const ForgotPassword = lazy(() => import("../views/ForgotPassword"));
const SettingsLayout = lazy(() => import("../../components/Settings/Layout"));
const ChangePassword = lazy(() => import("../views/ChangePassword"));
const ClaimDiscountLayout = lazy(
	() => import("../../components/ClaimDiscount/Layout")
);
const ClaimDiscount = lazy(() => import("../views/ClaimDiscount"));
const PartnerDetails = lazy(() => import("../views/PartnerDetails"));
const CompleteDiscountClaim = lazy(
	() => import("../views/CompleteDiscountClaim")
);
const DiscountHistory = lazy(() => import("../views/DiscountHistory"));
const Faq = lazy(() => import("../views/Faq"));
const UnlockAccount = lazy(() => import("../views/UnlockAccount"));

const BaseRoute = () => {
	const location = useLocation();
	const { isAuthenticated } = useSelector((state) => state.auth);

	const isAllowed = isAuthenticated;

	return (
		<AnimatePresence mode="wait" location={location} key={location.pathname}>
			{/* <ScrollToTop> */}
			<Routes>
				<Route path="/" element={<Navigate to="/partner-admin" />} />
				{/* <Route path="/partner-admin/login" element={<Login />} /> */}
				<Route path="/partner-admin" element={<Layout />}>
					<Route
						index
						element={
							<ProtectedRoutes isAllowed={isAllowed}>
								<ClaimDiscount />
							</ProtectedRoutes>
						}
					/>

					<Route path="login" element={<Login />} />
					<Route
						path="forgot-password"
						element={
							<Suspense fallback={<Loader />}>
								<ForgotPassword />
							</Suspense>
						}
					/>
					<Route
						path="unlock-account"
						element={
							<Suspense fallback={<Loader />}>
								<UnlockAccount />
							</Suspense>
						}
					/>

					<Route
						path="discount-claim"
						element={
							<Suspense fallback={<Loader />}>
								<ClaimDiscountLayout />
							</Suspense>
						}
					>
						<Route
							index
							element={
								<Suspense fallback={<Loader />}>
									<ProtectedRoutes isAllowed={isAllowed}>
										<ClaimDiscount />
									</ProtectedRoutes>
								</Suspense>
							}
						/>

						<Route
							path="partner-details"
							element={
								<Suspense fallback={<Loader />}>
									<ProtectedRoutes isAllowed={isAllowed}>
										<PartnerDetails />
									</ProtectedRoutes>
								</Suspense>
							}
						/>

						<Route
							path="complete-claim"
							element={
								<Suspense fallback={<Loader />}>
									<ProtectedRoutes isAllowed={isAllowed}>
										<CompleteDiscountClaim />
									</ProtectedRoutes>
								</Suspense>
							}
						/>
					</Route>

					<Route
						path="reports"
						element={
							<Suspense fallback={<Loader />}>
								<SettingsLayout />
							</Suspense>
						}
					>
						<Route
							path="discount-history"
							element={
								<Suspense fallback={<Loader />}>
									<ProtectedRoutes isAllowed={isAllowed}>
										<DiscountHistory />
									</ProtectedRoutes>
								</Suspense>
							}
						/>
					</Route>

					<Route
						path="help"
						element={
							<Suspense fallback={<Loader />}>
								<SettingsLayout />
							</Suspense>
						}
					>
						<Route
							path="faq"
							element={
								<Suspense fallback={<Loader />}>
									<ProtectedRoutes isAllowed={isAllowed}>
										<Faq />
									</ProtectedRoutes>
								</Suspense>
							}
						/>
					</Route>

					<Route
						path="settings"
						element={
							<Suspense fallback={<Loader />}>
								<SettingsLayout />
							</Suspense>
						}
					>
						<Route
							path="change-password"
							element={
								<Suspense fallback={<Loader />}>
									<ProtectedRoutes isAllowed={isAllowed}>
										<ChangePassword />
									</ProtectedRoutes>
								</Suspense>
							}
						/>
					</Route>
				</Route>

				<Route
					path="/logout"
					element={
						<Suspense fallback={<Loader />}>
							<Logout />
						</Suspense>
					}
				/>

				<Route
					path="*"
					element={
						<Suspense fallback={<Loader />}>
							<NotFound />
						</Suspense>
					}
				/>
			</Routes>
		</AnimatePresence>
	);
};

export default BaseRoute;
