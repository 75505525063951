import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useFormik } from "formik";
import * as yup from "yup";
import styled from "styled-components";
import { login } from "../../slices/authSlice";
import { hashString } from "../../utils/helpers/functions";

import {
	Box,
	Typography,
	Button,
	CircularProgress,
	LockIcon,
} from "../../components/common/muiComponents";
import TextFieldComponent from "../../components/common/TextFieldComponent";
import PasswordComponent from "../../components/common/PasswordComponent";
import ChangePasswordModal from "../../components/common/ChangePasswordModal";
import {
	specialCharacterCheck,
	digitCheck,
	uppercaseCheck,
	DEVICETYPE,
	ROLES,
	TOKENKEY,
	USERKEY,
	ROLEKEY,
	ACCOUNTTYPEKEY,
} from "../../utils/constants";
import { showSuccessToast, showErrorToast } from "../../utils/toastHelper";
import auth from "../../utils/helpers/auth";

const initialValues = {
	username: "",
	password: "",
};

const mainContainerStyles = {
	padding: { md: "2rem", sm: "1rem", xs: 0 },
};

const flexContainer = {
	display: "flex",
	justifyContent: "center",
	pb: { md: "3rem", xs: "2rem" },
};

const formContainerStyles = {
	width: "33rem",
	boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
	borderRadius: "1rem",
	maxWidth: "100%",
};

const validationSchema = yup.object({
	username: yup
		.string("Please enter your username")
		.min(3, "Username should be of minimum 3 characters length")
		.required("Username is required"),
	password: yup
		.string("Please enter your password")
		.min(7, "Password should be of minimum 7 characters length")
		.matches(
			specialCharacterCheck,
			"Password should contain at least one special character"
		)
		.matches(digitCheck, "Password should contain at least one digit")
		.matches(
			uppercaseCheck,
			"Password should contain at least one uppercase character"
		)
		.required("Password is required"),
});

const Login = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const location = useLocation();
	const [openPasswordChangeModal, setOpenPasswordsModal] = useState(false);

	const { bizFrom, region } = useSelector((state) => state.auth);

	useEffect(() => {
		if (auth.authenticate()) {
			navigate("/partner-admin/discount-claim");
		}
	}, [navigate]);

	const handleLogin = (user, locationData) => {
		if (locationData?.state?.from) {
			return locationData?.state?.from;
		}
		return "/partner-admin/discount-claim";
	};

	const handleSubmit = async (values, stopSubmitLoading, resetForm) => {
		try {
			const payload = {
				username: values.username.trim(),
				password: hashString(values.password.trim()),
				region: region?.region,
				bizFrom,
				accountType: ROLES.PARTNER,
				deviceType: DEVICETYPE,
			};
			const result = await dispatch(login(payload)).unwrap();

			stopSubmitLoading();
			resetForm();
			showSuccessToast(result.message);

			localStorage.setItem(TOKENKEY, result?.data?.accessToken);
			if (result?.data?.agent_password_change === 1) {
				setOpenPasswordsModal(true);
				return;
			}
			localStorage.setItem(USERKEY, JSON.stringify(result.data));
			localStorage.setItem(ROLEKEY, result?.data?.role?.toLowerCase());
			localStorage.setItem(
				ACCOUNTTYPEKEY,
				result?.data?.accountType?.toLowerCase()
			);
			window.location = handleLogin(result, location);
		} catch (error) {
			stopSubmitLoading();
			showErrorToast(error);
		}
	};

	const formik = useFormik({
		initialValues,
		validationSchema,
		onSubmit: (values) => {
			handleSubmit(
				values,
				() => formik.setSubmitting(false),
				() => formik.resetForm()
			);
		},
	});

	return (
		<Container>
			<Box sx={mainContainerStyles}>
				<Box sx={flexContainer}>
					<Box sx={formContainerStyles}>
						<Box sx={{ p: "1rem" }}>
							<Typography
								variant="h6"
								sx={{ textAlign: "center", fontWeight: 700 }}
							>
								Enter your login details
							</Typography>

							<Box
								component="form"
								sx={{
									p: { md: "3rem", xs: "1rem" },
								}}
								noValidate
								autoComplete="off"
								onSubmit={formik.handleSubmit}
							>
								<Box>
									<Box sx={{ mb: "1rem" }}>
										<TextFieldComponent
											value={formik.values.username}
											onChange={formik.handleChange}
											label="Username"
											id="username"
											name="username"
											required
											error={
												formik.touched.username &&
												Boolean(formik.errors.username)
											}
											helperText={
												formik.touched.username && formik.errors.username
											}
										/>
									</Box>
									<Box>
										<PasswordComponent
											value={formik.values.password}
											onChange={formik.handleChange}
											required
											id="password"
											error={
												formik.touched.password &&
												Boolean(formik.errors.password)
											}
											helperText={
												formik.touched.password && formik.errors.password
											}
										/>
									</Box>
								</Box>

								<Box sx={{ display: "flex", mb: "1rem" }}>
									<Button
										variant="text"
										component={Link}
										to="/partner-admin/forgot-password"
										sx={{ textTransform: "none", marginLeft: "auto" }}
										color="secondary"
									>
										Forgot password
									</Button>
								</Box>

								<Button
									type="submit"
									variant="contained"
									color="primary"
									fullWidth
									sx={{ padding: "1rem", mt: "1rem" }}
									disabled={formik.isSubmitting}
								>
									{formik.isSubmitting ? (
										<CircularProgress size="1.5rem" />
									) : (
										"login"
									)}
								</Button>
								<Box
									sx={{
										display: "flex",
										justifyContent: "center",
										mt: "1rem",
									}}
								>
									<Button
										variant="text"
										component={Link}
										to="/partner-admin/unlock-account"
										sx={{ textTransform: "none" }}
										color="secondary"
										startIcon={<LockIcon />}
									>
										Unlock Account
									</Button>
								</Box>
							</Box>
						</Box>
					</Box>
				</Box>
			</Box>

			<ChangePasswordModal
				open={openPasswordChangeModal}
				handleClose={() => setOpenPasswordsModal(false)}
			/>
		</Container>
	);
};

export default Login;

const Container = styled.div`
	padding: 2rem 4rem;

	@media (max-width: 768px) {
		padding: 2rem;
	}
`;
