import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { styled, useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import Toolbar from "@mui/material/Toolbar";
import GridViewIcon from "@mui/icons-material/GridView";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import ArrowDropDownOutlinedIcon from "@mui/icons-material/ArrowDropDownOutlined";
import ArrowDropUpOutlinedIcon from "@mui/icons-material/ArrowDropUpOutlined";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import LockResetOutlinedIcon from "@mui/icons-material/LockResetOutlined";
import PeopleAltOutlinedIcon from "@mui/icons-material/PeopleAltOutlined";
import HelpIcon from "@mui/icons-material/Help";
import TimelineIcon from "@mui/icons-material/Timeline";
import Tooltip from "@mui/material/Tooltip";

import DrawerItem from "./DrawerItem";
import DrawerItemExternal from "./DrawerItemExternal";
import { drawerWidth } from "../../utils/constants";
import config from "../../config";

const { docsClient } = config().secrets;

const openedMixin = (theme) => ({
	width: drawerWidth,
	transition: theme.transitions.create("width", {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.enteringScreen,
	}),
	overflowX: "hidden",
});

const closedMixin = (theme) => ({
	transition: theme.transitions.create("width", {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen,
	}),
	overflowX: "hidden",
	width: `calc(${theme.spacing(7)} + 1px)`,
	[theme.breakpoints.up("sm")]: {
		width: `calc(${theme.spacing(8)} + 1px)`,
	},
});

const Drawer = styled(MuiDrawer, {
	shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
	width: drawerWidth,
	flexShrink: 0,
	whiteSpace: "nowrap",
	boxSizing: "border-box",
	...(open && {
		...openedMixin(theme),
		"& .MuiDrawer-paper": openedMixin(theme),
	}),
	...(!open && {
		...closedMixin(theme),
		"& .MuiDrawer-paper": closedMixin(theme),
	}),
}));

const CollapibleContent = styled(Box)({
	marginLeft: ".5rem",
});

const AdminDrawer = ({ open, setOpen }) => {
	const [value, setValue] = useState(0);
	const [itemOnHover, setItemOnHover] = useState(null);
	const [innerWidth, setInnerWidth] = useState(window.innerWidth);
	const [openCollapse, setOpenCollapse] = useState(false);
	const [reportsCollapse, setReportsCollapse] = useState(false);
	const [helpCollapse, setHelpCollapse] = useState(false);

	const theme = useTheme();
	const location = useLocation();
	const showDrawer = useMediaQuery(theme.breakpoints.up("md"));

	useEffect(() => {
		let isMounted = true;

		function handleResize() {
			setInnerWidth(window.innerWidth);
			if (innerWidth < 960 && isMounted && showDrawer) {
				setOpen(false);
			} else {
				setOpen(true);
			}
		}

		window.addEventListener("resize", handleResize);
		return () => {
			window.addEventListener("resize", handleResize);
			isMounted = false;
		};
	});

	useEffect(() => {
		let isMounted = true;

		if (innerWidth < 960 && isMounted && showDrawer) {
			setOpen(false);
		}

		return () => {
			isMounted = false;
		};
	}, [innerWidth]);

	useEffect(() => {
		let isMounted = true;

		if (isMounted && showDrawer) {
			if (
				location.pathname.includes("/partner-admin/discount-claim") &&
				value !== 0
			) {
				setValue(0);
			}
			if (
				location.pathname === "/partner-admin/reports/discount-history" &&
				value !== 1
			) {
				setValue(1);
			}
			if (
				location.pathname === "/partner-admin/settings/change-password" &&
				value !== 2
			) {
				setValue(2);
			}
			if (location.pathname === "/partner-admin/help/faq" && value !== 3) {
				setValue(3);
			}
		}

		return () => {
			isMounted = false;
		};
	}, [value, showDrawer, location.pathname]);

	return (
		<Drawer
			variant="permanent"
			anchor="left"
			open={open}
			// sx={{
			// 	display: {
			// 		xs: "none",
			// 		md: "block",
			// 	},
			// }}
		>
			<Toolbar />
			<Divider />
			<List>
				<DrawerItem
					text="Claim Discount"
					open={open}
					Icon={GridViewIcon}
					linkTo="/partner-admin/discount-claim"
					setValue={setValue}
					setItemOnHover={setItemOnHover}
					itemOnHover={itemOnHover}
					value={value}
					itemValue={0}
				/>

				<Tooltip title="Reports">
					<ListItemButton
						onClick={() => setReportsCollapse(!reportsCollapse)}
						sx={{ borderBottom: "1px solid rgba(0, 0, 0, 0.12)" }}
					>
						<ListItemIcon>
							<PeopleAltOutlinedIcon />
						</ListItemIcon>
						<ListItemText primary="Reports" />
						{reportsCollapse ? (
							<ArrowDropUpOutlinedIcon />
						) : (
							<ArrowDropDownOutlinedIcon />
						)}
					</ListItemButton>
				</Tooltip>
				<Collapse in={reportsCollapse} timeout="auto" unmountOnExit>
					<CollapibleContent>
						<DrawerItem
							text="Discount History"
							open={open}
							Icon={TimelineIcon}
							linkTo="/partner-admin/reports/discount-history"
							setValue={setValue}
							setItemOnHover={setItemOnHover}
							itemOnHover={itemOnHover}
							value={value}
							itemValue={1}
						/>
					</CollapibleContent>
				</Collapse>

				<Tooltip title="Help">
					<ListItemButton
						onClick={() => setHelpCollapse(!helpCollapse)}
						sx={{ borderBottom: "1px solid rgba(0, 0, 0, 0.12)" }}
					>
						<ListItemIcon>
							<PeopleAltOutlinedIcon />
						</ListItemIcon>
						<ListItemText primary="Help" />
						{helpCollapse ? (
							<ArrowDropUpOutlinedIcon />
						) : (
							<ArrowDropDownOutlinedIcon />
						)}
					</ListItemButton>
				</Tooltip>
				<Collapse in={helpCollapse} timeout="auto" unmountOnExit>
					<CollapibleContent>
						<DrawerItemExternal
							text="Help Center"
							open={open}
							Icon={HelpIcon}
							linkTo={`${docsClient}/help-center`}
							setValue={setValue}
							setItemOnHover={setItemOnHover}
							itemOnHover={itemOnHover}
							value={value}
							itemValue={0}
						/>
						<DrawerItem
							text="FAQs"
							open={open}
							linkTo="/partner-admin/help/faq"
							setValue={setValue}
							setItemOnHover={setItemOnHover}
							itemOnHover={itemOnHover}
							value={value}
							itemValue={3}
							Icon={LockResetOutlinedIcon}
						/>
					</CollapibleContent>
				</Collapse>

				<>
					<Tooltip title="Settings">
						<ListItemButton
							onClick={() => setOpenCollapse(!openCollapse)}
							sx={{ borderBottom: "1px solid rgba(0, 0, 0, 0.12)" }}
						>
							<ListItemIcon>
								<SettingsOutlinedIcon />
							</ListItemIcon>
							<ListItemText primary="Settings" />
							{openCollapse ? (
								<ArrowDropUpOutlinedIcon />
							) : (
								<ArrowDropDownOutlinedIcon />
							)}
						</ListItemButton>
					</Tooltip>
					<Collapse in={openCollapse} timeout="auto" unmountOnExit>
						<CollapibleContent>
							<DrawerItem
								text="Change Password"
								open={open}
								linkTo="/partner-admin/settings/change-password"
								setValue={setValue}
								setItemOnHover={setItemOnHover}
								itemOnHover={itemOnHover}
								value={value}
								itemValue={2}
								Icon={LockResetOutlinedIcon}
							/>
						</CollapibleContent>
					</Collapse>
				</>
				<DrawerItem
					text="Log out"
					open={open}
					Icon={LogoutOutlinedIcon}
					linkTo="/logout"
					setValue={setValue}
					setItemOnHover={setItemOnHover}
					itemOnHover={itemOnHover}
					value={value}
					itemValue={14}
				/>
			</List>
		</Drawer>
	);
};

export default AdminDrawer;
