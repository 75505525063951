const env = process.env.NODE_ENV || "development";

let cache;

const config = () => {
	if (!cache) {
		cache = Object.freeze({
			env,
			secrets: {
				apiHost:
					process.env.REACT_APP_ENVIRONMENT === "development"
						? "https://core-staging.smartdeals.com.ng"
						: "https://core.smartdeals.com.ng",
				socketUrl:
					process.env.REACT_APP_ENVIRONMENT === "development"
						? "wss://core-staging.smartdeals.com.ng:9390"
						: "wss://core.smartdeals.com.ng:9290",
				encryptionPassword:
					process.env.REACT_APP_ENVIRONMENT === "development"
						? process.env.REACT_APP_ENCRYPTION_PASSWORD_DEV
						: process.env.REACT_APP_ENCRYPTION_PASSWORD,
				encryptionSecretKey:
					process.env.REACT_APP_ENVIRONMENT === "development"
						? process.env.REACT_APP_ENCRYPTION_SECRETKEY_DEV
						: process.env.REACT_APP_ENCRYPTION_SECRETKEY,
				chatSocketUrl:
					process.env.REACT_APP_ENVIRONMENT === "development"
						? "https://core-staging.smartdeals.com.ng:9490"
						: "https://core.smartdeals.com.ng:9490",
				docsClient:
					process.env.REACT_APP_ENVIRONMENT === "development"
						? "https://docs-staging.smartdeals.com.ng"
						: "https://docs.smartdeals.com.ng",
				hoempageUrl:
					process.env.REACT_APP_ENVIRONMENT === "development"
						? "https://app-staging.smartdeals.com.ng"
						: "https://app.smartdeals.com.ng",
			},
		});
	}
	return cache;
};

export default config;
