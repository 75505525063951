import { Outlet } from "react-router-dom";

import { Box, Toolbar } from "../common/muiComponents";
import Navigation from "./Navigation";
import Footer from "./Footer";
import { SCREENS_WITH_FOOTER } from "../../utils/constants";

const Layout = () => {
	return (
		<Box sx={{ display: "flex" }}>
			<Navigation />
			<Box
				component="main"
				sx={{
					flexGrow: 1,
					minHeight: "100vh",
					width: "100%",
				}}
			>
				<Toolbar />
				<Outlet />
				{(SCREENS_WITH_FOOTER || [])?.includes(window.location.pathname) && (
					<Footer />
				)}
			</Box>
		</Box>
	);
};

export default Layout;
