import * as React from "react";
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	useTheme,
	Slide,
	useMediaQuery,
} from "./muiComponents";

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

export default function AlertDialogSlide({
	open,
	handleClose,
	externalSiteUrl,
}) {
	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

	return (
		<Dialog
			open={open}
			TransitionComponent={Transition}
			keepMounted
			onClose={handleClose}
			aria-describedby="alert-dialog-slide-description"
			fullScreen={fullScreen}
			sx={{
				borderRadius: "50px",
				"& .MuiDialog-paper": {
					borderRadius: "20px",
					maxWidth: { md: "80vw !important", xs: "100vw" },
					overflow: "hidden", // Hide scrollbars
				},
				"&::-webkit-scrollbar": {
					width: "0", // Hide the width of the scrollbar
				},
				"&::-webkit-scrollbar-thumb": {
					background: "transparent", // Hide the scrollbar thumb
				},
			}}
		>
			<DialogContent
				sx={{
					width: { md: "80vw", xs: "100vw" },
					height: { md: "80vh", xs: "100vh" },
					backgroundColor: "white",
					overflow: "hidden",
					"&::-webkit-scrollbar": {
						width: "0", // Hide the width of the scrollbar
					},
					"&::-webkit-scrollbar-thumb": {
						background: "transparent", // Hide the scrollbar thumb
					},
				}}
			>
				{/* <div
						style={{
							width: "80vw",
							height: "80vh",
							margin: "auto",
							marginTop: "10vh",
							backgroundColor: "white",
							borderRadius: "4px",
							overflow: "hidden",
						}}
					> */}
				<iframe
					title="Terms of use"
					src={externalSiteUrl}
					style={{ width: "100%", height: "100%", border: "none" }}
				/>
				{/* </div> */}
			</DialogContent>
			<DialogActions>
				<Button onClick={handleClose}>close</Button>
			</DialogActions>
		</Dialog>
	);
}
