import config from "../config";

const { docsClient } = config().secrets;

const specialCharacterCheck = /[ `!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/;
const digitCheck = /[0-9]/;
const uppercaseCheck = /[A-Z]/;
const TOKENKEY = "accessToken";
const USERKEY = "user";
const ROLEKEY = "role";
const ACCOUNTTYPEKEY = "accountType";
const REGION_KEY = "region";
const VERIFIED_USER_DETAILS = "verifiedUserDetails";

const ROLES = Object.freeze({
	ADMIN: "admin",
	MEMBER: "member",
	PARTNER: "partner",
	CSU: "csu",
	FINCON: "fincon",
	OPERATIONS: "operations",
	LOGISTICS: "logistics",
	EXECUTIVE: "exec",
	AUATON: "auaton",
});

const ACCOUNT_TYPES = Object.freeze({
	ADMIN: "admin",
	COLLAB: "collab",
	COOP: "coop",
});

const currencySymbolMap = {
	NG: "₦",
	KE: "Ksh",
	GH: "GH₵",
	ZA: "ZAR",
	ZM: "ZK",
};

const DEVICETYPE = "WEB";

const drawerWidth = 230;

const chatTimeDateFnsFormat = "yyyy-MM-dd HH:mm";

const mobileRoutes = [
	{ name: "Discount Claimed", link: "/partner-admin/discount-claim" },
	{ name: "Discount History", link: "/partner-admin/reports/discount-history" },
	{ name: "Help Center", link: `${docsClient}/help-center` },
	{ name: "FAQs", link: "/partner-admin/faq" },
	{ name: "Change Password", link: "/partner-admin/settings/change-password" },
];

const routesByRole = {
	[ROLES.ADMIN]: [
		{ name: "Dashboard", link: "/dashboard" },
		{ name: "Partners", link: "/partners" },
		{ name: "Events", link: "/events" },
		{ name: "Suggested Partners", link: "/suggested-partners" },
		{ name: "Partners Discounts", link: "/partners-discounts" },
		{ name: "Members", link: "/members" },
		{ name: "Products", link: "/products" },
		{ name: "Live Deals", link: "/live-deals" },
		{ name: "Deals Won", link: "/deals-won" },
		{ name: "Checkout", link: "/checkout" },
		{ name: "Dicount Claimed", link: "/discount-claimed" },
		{ name: "Admin Users", link: "/settings/admin-users" },
		{ name: "Change Password", link: "/settings/change-password" },
		{ name: "Admin Roles", link: "/settings/user-roles" },
		{ name: "Subscription Plans", link: "/settings/subscription-plans" },
		{ name: "App Version", link: "/settings/update-app-version" },
		{
			name: "Extend Subscription",
			link: "/settings/extend-subscription-validity",
		},
		{ name: "Notifications", link: "/notifications" },
		{ name: "Activity Log", link: "/activity-log" },
		{ name: "Adverts", link: "/adverts" },
		{ name: "Mailing List", link: "/mailing-list" },
		{ name: "Contact Us", link: "/contact-us" },
		{ name: "Delivery", link: "/delivery" },
		{ name: "Failed Withdrawals", link: "/failed-withdrawals" },
		{ name: "Wallet Report", link: "/wallet-report" },
		{ name: "Wallet Balances", link: "/wallet-balances" },
		{ name: "Users", link: "/users" },
		{ name: "Executive Summary", link: "/executive-summary" },
		{ name: "Bill Payment", link: "/bill-payment" },
		{ name: "Subscriptions", link: "/subscriptions" },
		{ name: "Banks", link: "/banks" },
		{ name: "Verify Account", link: "/verify-account" },
		{ name: "Flutter Log", link: "/flutter-log" },
		{ name: "Paystack Log", link: "/paystack-log" },
		{ name: "Providus Log", link: "/providus-log" },
		{ name: "Create Admin", link: "/create-admin" },
		{ name: "Push Notification", link: "/push-notification" },
		{ name: "Delayed Transactions", link: "/delayed-transactions" },
		{ name: "Pending Inflow", link: "/pending-inflow" },
	],
	[ROLES.CSU]: [
		{ name: "Dashboard", link: "/dashboard" },
		{ name: "Partners", link: "/partners" },
		{ name: "Events", link: "/events" },
		{ name: "Suggested Partners", link: "/suggested-partners" },
		{ name: "Partners Discounts", link: "/partners-discounts" },
		{ name: "Members", link: "/members" },
		{ name: "Products", link: "/products" },
		{ name: "Live Deals", link: "/live-deals" },
		{ name: "Notifications", link: "/notifications" },
		{ name: "Adverts", link: "/adverts" },
		{ name: "Mailing List", link: "/mailing-list" },
		{ name: "Contact Us", link: "/contact-us" },
		{ name: "Delivery", link: "/delivery" },
		{ name: "Push Notification", link: "/push-notification" },
		{ name: "User Referrals", link: "/user-referrals" },
		{ name: "Support Chat", link: "/support-chat" },
	],
	[ROLES.FINCON]: [
		{ name: "Dashboard", link: "/dashboard" },
		{ name: "Deals Won", link: "/deals-won" },
		{ name: "Checkout", link: "/checkout" },
		{ name: "Dicount Claimed", link: "/discount-claimed" },
		{ name: "Notifications", link: "/notifications" },
		{ name: "Adverts", link: "/adverts" },
		{ name: "Mailing List", link: "/mailing-list" },
		{ name: "Contact Us", link: "/contact-us" },
		{ name: "Delivery", link: "/delivery" },
		{ name: "Failed Withdrawals", link: "/failed-withdrawals" },
		{ name: "Wallet Report", link: "/wallet-report" },
		{ name: "Wallet Balances", link: "/wallet-balances" },
		{ name: "Users", link: "/users" },
		{ name: "Executive Summary", link: "/executive-summary" },
		{ name: "Banks", link: "/banks" },
		{ name: "Verify Account", link: "/verify-account" },
		{ name: "Flutter Log", link: "/flutter-log" },
		{ name: "Paystack Log", link: "/paystack-log" },
		{ name: "Providus Log", link: "/providus-log" },
		{ name: "Payment Requests", link: "/payment-requests" },
		{ name: "Delayed Transactions", link: "/delayed-transactions" },
		{ name: "Pending Inflow", link: "/pending-inflow" },
	],
	[ROLES.LOGISTICS]: [{ name: "Checkout", link: "/checkout" }],
	[ROLES.EXECUTIVE]: [
		{ name: "Dasboard", link: "/dashboard" },
		{ name: "Partners", link: "/partners" },
		{ name: "Events", link: "/events" },
		{ name: "Suggested Partners", link: "/suggested-partners" },
		{ name: "Partners Discounts", link: "/partners-discounts" },
		{ name: "Members", link: "/members" },
		{ name: "Products", link: "/products" },
		{ name: "Live Deals", link: "/live-deals" },
		{ name: "Deals Won", link: "/deals-won" },
		{ name: "Checkout", link: "/checkout" },
		{ name: "Dicount Claimed", link: "/discount-claimed" },
		{ name: "Admin Users", link: "/settings/admin-users" },
		{ name: "Change Password", link: "/settings/change-password" },
		{ name: "Admin Roles", link: "/settings/user-roles" },
		{ name: "Subscription Plans", link: "/settings/subscription-plans" },
		{ name: "App Version", link: "/settings/update-app-version" },
		{
			name: "Extend Subscription",
			link: "/settings/extend-subscription-validity",
		},
		{ name: "Notifications", link: "/notifications" },
		{ name: "Activity Log", link: "/activity-log" },
		{ name: "Adverts", link: "/adverts" },
		{ name: "Mailing List", link: "/mailing-list" },
		{ name: "Contact Us", link: "/contact-us" },
		{ name: "Delivery", link: "/delivery" },
		{ name: "Wallet Report", link: "/wallet-report" },
		{ name: "Wallet Balances", link: "/wallet-balances" },
		{ name: "Users", link: "/users" },
		{ name: "Executive Summary", link: "/executive-summary" },
		{ name: "Bill Payment", link: "/bill-payment" },
		{ name: "Banks", link: "/banks" },
		{ name: "Verify Account", link: "/verify-account" },
		{ name: "Flutter Log", link: "/flutter-log" },
		{ name: "Paystack Log", link: "/paystack-log" },
		{ name: "Providus Log", link: "/providus-log" },
		{ name: "Create Admin", link: "/create-admin" },
		{ name: "Payment Requests", link: "/payment-requests" },
		{ name: "Complete Transfer", link: "/complete-transfer" },
		{ name: "Push Notification", link: "/push-notification" },
		{ name: "Delayed Transactions", link: "/delayed-transactions" },
		{ name: "Pending Inflow", link: "/pending-inflow" },
		{ name: "User Referrals", link: "/user-referrals" },
		{ name: "Support Chat", link: "/support-chat" },
	],
	[ROLES.DEFAULT]: [{ name: "", link: "*" }],
};

const SCREENS_WITHOUT_DRAWER = [
	"/partner-admin/login",
	"/partner-admin/forgot-password",
	"/partner-admin/unlock-account",
];
const SCREENS_WITH_FOOTER = [
	"/partner-admin/login",
	"/partner-admin/forgot-password",
	"/partner-admin/unlock-account",
];

const OTP_PROCESSES = {
	WITHDRAWAL: "withdrawal",
	SUBSCRIPTION: "subscription",
	UNLOCK_ACCOUNT: "unlockaccount",
	CHANGE_PIN: "changepin",
	CHANGE_TRANSACTION_LIMIT: "changetransactionlimit",
	BVN_VERIFICATION: "bvnverification",
	REDEEM_DEAL: "redeemdeal",
	FORGOT_PASSWORD: "forgotpassword",
	RESET_PIN: "resetPin",
	CHANGE_PASSWORD: "changepassword",
};

export {
	specialCharacterCheck,
	digitCheck,
	uppercaseCheck,
	TOKENKEY,
	USERKEY,
	ROLEKEY,
	ACCOUNTTYPEKEY,
	ROLES,
	ACCOUNT_TYPES,
	currencySymbolMap,
	drawerWidth,
	REGION_KEY,
	DEVICETYPE,
	chatTimeDateFnsFormat,
	routesByRole,
	SCREENS_WITHOUT_DRAWER,
	SCREENS_WITH_FOOTER,
	VERIFIED_USER_DETAILS,
	mobileRoutes,
	OTP_PROCESSES,
};
