import { useState } from "react";

import { useMediaQuery, useTheme } from "../common/muiComponents";
import Navbar from "./NavBar";
import Drawer from "./Drawer";
import { SCREENS_WITHOUT_DRAWER } from "../../utils/constants";

const Navigation = ({ showDrawer = true }) => {
	const [open, setOpen] = useState(true);

	const theme = useTheme();
	const isBigScreen = useMediaQuery(theme.breakpoints.up("md"));

	// Check if the current screen is in the list of screens without the drawer
	const shouldHideDrawer = SCREENS_WITHOUT_DRAWER.includes(
		window.location.pathname
	);

	return (
		<div>
			<Navbar openDrawer={open} toggleDrawer={setOpen} />
			{isBigScreen && showDrawer && !shouldHideDrawer && (
				<Drawer open={open} setOpen={setOpen} />
			)}
		</div>
	);
};

export default Navigation;
