import { ToastContainer } from "react-toastify";
import { QueryClientProvider, QueryClient } from "react-query";
import { ThemeProvider } from "@mui/material/styles";
import { Provider } from "react-redux";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { store } from "./store/index";
import customTheme from "./config/themeConfig";
import BaseRoute from "./pages/routes/BaseRoutes";
import { loginSuccess, setRegion } from "./slices/authSlice";
import auth from "./utils/helpers/auth";
import { USERKEY, ROLEKEY, REGION_KEY } from "./utils/constants";
import "react-toastify/dist/ReactToastify.css";
// import { encryption, decryption } from "./utils/helpers/functions";

if (auth.authenticate()) {
	const user = localStorage.getItem(USERKEY);
	const bizFrom = localStorage.getItem("bizFrom");
	const parsedUser = JSON.parse(user);
	const region = localStorage.getItem(REGION_KEY);
	const parsedRegion = JSON.parse(region);
	store.dispatch(
		loginSuccess({
			...parsedUser,
			role: localStorage.getItem(ROLEKEY),
			bizFrom,
		})
	);
	if (region) {
		store.dispatch(setRegion(parsedRegion));
	}
}

function App() {
	const queryClient = new QueryClient({
		defaultOptions: {
			queries: {
				refetchOnWindowFocus: false,
				retry: false,
				cacheTime: 0,
			},
		},
	});

	return (
		<Provider store={store}>
			<QueryClientProvider client={queryClient}>
				<ThemeProvider theme={customTheme}>
					<LocalizationProvider dateAdapter={AdapterDateFns}>
						<ToastContainer />
						<BaseRoute />
					</LocalizationProvider>
				</ThemeProvider>
			</QueryClientProvider>
		</Provider>
	);
}

export default App;
