import { TOKENKEY, ACCOUNTTYPEKEY } from "../constants";

const auth = {
	authenticate: () => {
		const token = localStorage.getItem(TOKENKEY);
		const accountType = localStorage.getItem(ACCOUNTTYPEKEY);
		if (token && accountType) {
			return true;
		}
		return false;
	},
	getUserRole: () => {
		const role = localStorage.getItem(ACCOUNTTYPEKEY);
		if (role) {
			return role;
		}
		return "";
	},
};

export default auth;
